import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import AOS from "aos";
import Headroom from "headroom.js";
import Flickity from "flickity";

document.addEventListener("DOMContentLoaded", function () {
	gsap.registerPlugin(ScrollToPlugin);

	/*
	-------------------------------
	Main banner
	-------------------------------
	*/
	let slideDelay = 4;
	let slideDuration = 0.4;
	let snapX;

	let slides = document.querySelectorAll(".slide");
	let progressWrap = gsap.utils.wrap(0, 1);

	let numSlides = slides.length;

	gsap.set(slides, {
		// backgroundColor: "#4b3492",
		xPercent: (i) => i * 100,
	});

	let wrap = gsap.utils.wrap(-100, (numSlides - 1) * 100);
	let timer = gsap.delayedCall(slideDelay, autoPlay);

	let animation = gsap.to(slides, {
		xPercent: "+=" + numSlides * 100,
		duration: 1,
		ease: "none",
		paused: true,
		repeat: -1,
		modifiers: {
			xPercent: wrap,
		},
	});

	let proxy = document.createElement("div");
	let slideAnimation = gsap.to({}, {});
	let slideWidth = 0;
	let wrapWidth = 0;
	resize();

	window.addEventListener("resize", resize);

	function animateSlides(direction) {
		timer.restart(true);
		slideAnimation.kill();

		let x = snapX(gsap.getProperty(proxy, "x") + direction * slideWidth);

		slideAnimation = gsap.to(proxy, {
			x: x,
			duration: slideDuration,
			onUpdate: updateProgress,
		});
	}

	function autoPlay() {
		animateSlides(-1);
	}

	function updateProgress() {
		animation.progress(
			progressWrap(gsap.getProperty(proxy, "x") / wrapWidth)
		);
	}

	function resize() {
		let norm = gsap.getProperty(proxy, "x") / wrapWidth || 0;

		slideWidth = slides[0].offsetWidth;
		wrapWidth = slideWidth * numSlides;
		snapX = gsap.utils.snap(slideWidth);

		gsap.set(proxy, {
			x: norm * wrapWidth,
		});

		animateSlides(0);
		slideAnimation.progress(1);
	}

	/*
	-------------------------------
	Menu nav
	-------------------------------
	*/
	const menubtn = document.querySelector(".main-header .side-toggle button");

	const navtimeline = gsap.timeline({ paused: true });

	navtimeline
		.to(".menu-overlay", {
			css: { visibility: "visible", opacity: "1" },
		})
		.from(".menu-overlay .bg", {
			xPercent: -100,
			duration: 0.75,
			ease: "power3.out",
		})
		.from(".menu-overlay .poster img", { opacity: "0", duration: 1 })
		.from(
			".menu-overlay .menu ul li",
			{
				x: "-=50px",
				opacity: 0,
				stagger: 0.1,
				duration: 1,
				ease: "power3.out",
			},
			"-=100%"
		)
		.from(
			".menu-overlay .info",
			{
				x: "-=50px",
				opacity: 0,
				duration: 1,
				ease: "power3.out",
			},
			"-=85%"
		);

	menubtn.addEventListener("click", (e) => {
		e.preventDefault();
		document.querySelector("body").classList.add("overhidden");
		navtimeline.timeScale(1).play();
	});

	const nav = document.querySelectorAll(".menu-overlay .menu ul li a");
	nav.forEach((item) => {
		item.addEventListener("click", (e) => {
			e.preventDefault();
			navtimeline.timeScale(2).reverse();
			console.log(item.dataset.link);
			if (navtimeline.reversed()) {
				document.querySelector("body").classList.remove("overhidden");
				gsap.to(window, {
					duration: 1.5,
					scrollTo: `#${item.dataset.link}`,
				});
			}
		});
	});

	// Click logo arriba
	const logobtn = document.querySelector(".main-header .logo");
	logobtn.addEventListener("click", (e) => {
		e.preventDefault();
		gsap.to(window, { duration: 1.5, scrollTo: 0 });
	});

	// Click a formulario
	const contactoBtn = document.querySelector(".welcome .cta a");
	contactoBtn.addEventListener("click", (e) => {
		e.preventDefault();
		gsap.to(window, {
			duration: 1.5,
			scrollTo: "#contacto",
		});
	});

	/*
	-------------------------------
	Animaciones scroll
	-------------------------------
	*/
	AOS.init();

	let scrollRef = 0;
	window.addEventListener("scroll", function () {
		// increase value up to 10, then refresh AOS
		if (scrollRef <= 10) {
			scrollRef++;
		} else {
			AOS.refresh();
		}
	});

	/*
	-------------------------------
	Header
	-------------------------------
	*/
	// grab an element
	const myElement = document.querySelector(".main-header");
	// construct an instance of Headroom, passing the element
	const headroom = new Headroom(myElement, {
		// vertical offset in px before element is first unpinned
		offset: 0,
		// or you can specify offset individually for up/down scroll
		offset: {
			up: 100,
			down: 50,
		},
		// scroll tolerance in px before state changes
		tolerance: 0,
		// or you can specify tolerance individually for up/down scroll
		tolerance: {
			up: 5,
			down: 0,
		},
		// css classes to apply
		classes: {
			// when element is initialised
			initial: "headroom",
			// when scrolling up
			pinned: "headroom--pinned",
			// when scrolling down
			unpinned: "headroom--unpinned",
			// when above offset
			top: "headroom--top",
			// when below offset
			notTop: "headroom--not-top",
			// when at bottom of scroll area
			bottom: "headroom--bottom",
			// when not at bottom of scroll area
			notBottom: "headroom--not-bottom",
			// when frozen method has been called
			frozen: "headroom--frozen",
			// multiple classes are also supported with a space-separated list
			pinned: "headroom--pinned foo bar",
		},
	});
	// initialise
	headroom.init();
});
